<template>
    <div class="center-content">
      <div id="mapContainer" class="map"></div>
    </div>
  </template>

<script>
import option from './js/map-option'
// import echarts from 'echarts'
// 修改为
import * as echarts from 'echarts'
export default {
  mounted () {
    this.mapEchartsInit()
  },
  methods: {
    mapEchartsInit () {
      const myChart = echarts.init(document.getElementById('mapContainer'))
      myChart.setOption(option, true)
    }
  }
}
</script>

  <style lang="less">
    .center-content {
      width: 100%;
      height: 100%;
    }
    .map {
        width: 100%;
        height: 100%;
    }
  </style>
