
// 数据格式 地图上标点为citys，value为经纬度，symbolSize为标记点的大小
// 数据格式 moveLines，fromName为线条出发城市，toName为线条到达城市，coords为数组经纬度，第一个为出发点经纬度，第二个为到达点经纬度
// import echarts from 'echarts'
// 修改为
import * as echarts from 'echarts'

var allData = {
  citys: [{ name: '中国农业科学院麻类研究所', value: [112.89708431206891, 28.215208345690932, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '龙骧集团', value: [113.00256316794182, 28.19955509585358, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '龙湾壹号', value: [112.98787389638836, 28.253629646009944, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '龙之梦臻品酒店', value: [112.99176610566388, 28.196860105895862, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓阳和景', value: [112.90385644570027, 28.205814020811914, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷林语c区地下停车场', value: [112.86757521393805, 28.22890113054207, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷林语E1区停车场', value: [112.87490224963537, 28.227156232906104, 32], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷林语BⅡ区停车场', value: [112.8711577325996, 28.225806216734682, 10], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷星辰停车场', value: [112.85727525404215, 28.21984433111654, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷明珠', value: [112.88781954253187, 28.207690063536347, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷新世界', value: [112.85674484919257, 28.205020779861957, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷小镇停车场', value: [112.891472, 28.238239, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷小学', value: [112.88684696797736, 28.21690336971796, 9], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷坐标停车场', value: [112.88898817900363, 28.216899695499354, -19], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '麓谷国际工业园', value: [112.86891870868178, 28.222378021382337, 14], symbolSize: 5, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷和馨园b区', value: [112.85230074390955, 28.214894676516295, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷企业广场', value: [112.880354547264, 28.2121464340884, 1], symbolSize: 10, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓谷·沐春园', value: [112.8989535105798, 28.220252892897978, -364], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '麓景裕园', value: [112.89596859160093, 28.210829085715538, -2], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '麓景新贵停车场', value: [113.01443243146612, 28.212867076100007, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓山润城小区', value: [112.87824948015889, 28.203338409524, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '麓山枫情停车场', value: [112.905646865273, 28.205975581567007, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鹿城饭店停车场', value: [112.66748540538694, 28.015197332998042, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鹏程世家', value: [112.98729410820194, 28.205952748917944, -2], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '鹏基·诺亚山林西区', value: [113.09708983297733, 28.246000338898273, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鸿铭中心', value: [112.99402084912596, 28.18669811966935, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鸿江苑', value: [112.97086924944637, 28.267074476923156, -14], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '鸿景雅苑', value: [112.9847020031389, 28.18648370047947, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鸿信大厦', value: [112.96946034147378, 28.186497013222162, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鸡鸭恋食堂', value: [112.97938619284453, 28.184578138239452, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高鑫麓城二期', value: [112.9224723352201, 28.221317112003604, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高桥电脑大世界', value: [113.01934712886595, 28.183406632716075, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高桥现代商贸城增补项目', value: [113.01815277527999, 28.17696790978483, -5], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '高桥现代商贸城', value: [113.0181466731061, 28.176962498248866, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高桥国际', value: [113.0199972539599, 28.1721492285105, 3], symbolSize: 10, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高晟华成', value: [112.88004583462049, 28.20228406844622, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高新科技园', value: [120.165002837929, 30.2879470065532, -1], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '高新区地税局', value: [112.88938656328259, 28.219695260200634, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '高信向日葵中区', value: [112.92024742161556, 28.21440777308867, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '骏达大厦', value: [112.83942548978376, 28.202282664946534, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香海·西岸', value: [112.95071879950294, 28.232282599547904, 32], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香泽南湖大厦', value: [113.00477919084878, 28.20676577180484, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香江锦园', value: [112.981806, 28.222587, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香江·世纪名城', value: [117.32894935118046, 31.877517886717207, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香樟鑫都', value: [113.02332479000673, 28.14034621999733, 52], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香樟景苑', value: [113.01563979400574, 28.140389617266433, 17], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香樟·兰亭', value: [113.0223937491106, 28.141662334185153, 41], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香槟花苑', value: [113.07927411218313, 28.235668678977543, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '香槟小镇', value: [113.07943510105208, 28.23504201968422, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '颐而康停车处', value: [112.97795127590886, 28.186466184667577, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '颐美会现代城', value: [112.97991764149602, 28.192556167199026, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '领御大厦', value: [112.97161859307151, 28.207517024239504, 17], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '领东汇停车场', value: [113.12520860234208, 28.202205896727744, -2], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '顺风楼', value: [113.00503386796328, 28.210293096394928, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '顺天黄金海岸', value: [112.97954799101156, 28.25338889957112, -2], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '顺天·黄金海岸大酒店', value: [112.97995365016743, 28.25204388683466, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '青竹湖霞凝小区', value: [112.94885687457477, 28.348068, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '青竹湖国际会展中心', value: [112.95608360674596, 28.34408067824035, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '青税小区', value: [114.3871895583972, 30.62105259460301, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '青枫大厦', value: [112.94622406122788, 28.19729366811922, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '雨花区公安局', value: [113.01966514865555, 28.148659902140526, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '集星码头', value: [112.93244572720437, 28.32442099343107, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '雅致名园', value: [113.04498257736587, 28.143973608495752, 18], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '雅尊酒店', value: [112.99255571516659, 28.201232649481728, 7], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '雅和苑停车场', value: [112.93776541341845, 28.134094501925482, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '雄新华府', value: [113.01141585697091, 28.25331749867126, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '阿波罗商业广场', value: [113.00346238146925, 28.200428296200446, 1], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '阳光锦城', value: [112.99196582066985, 28.15737765416692, 13], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '阳光晶诚', value: [112.90102576640005, 112.90102576640005, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '阳光新城', value: [112.93820933196204, 28.233812811969077, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长远大厦', value: [112.97976288924615, 28.204373586777766, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长缆电工科技股份有限公司', value: [112.8732799142657, 28.223840338309376, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙鼎道汽车销售服务有限公司', value: [112.85255197723336, 28.207178165217577, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙黄花国际机场-t1航站楼', value: [113.21114025174909, 28.195978306653462, 44], symbolSize: 10, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙高新技术工程学校', value: [112.85589084011812, 112.85589084011812, -23], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '沙驰宝汽车销售服务有限公司', value: [112.85504351423941, 28.20708582076012, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙鑫广合汽车销售服务有限公司', value: [112.85393888402565, 28.212786776830338, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙金属包装厂', value: [112.99443371029386, 28.236021990017587, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙软件园', value: [112.88769681404735, 28.21832375728395, 1], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙路德行汽车有限公司', value: [112.85502441511231, 28.209962143338633, 14], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙赛格数码广场', value: [113.00436475152112, 28.197187094912376, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙裕麓汽车销售服务有限公司', value: [112.85351974738344, 28.20663617477256, 15], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙艺术职业学院', value: [112.93203532205392, 28.171016227863806, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙美爵酒店', value: [112.9703242096342, 28.19933825192662, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙美东雷克萨斯汽车销售服务有限公司', value: [112.85483781571625, 28.208354286316183, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙第5大道1号停车场', value: [113.00503391938938, 28.1810899637132, 40], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙矿山研究院二村停车场', value: [112.93747616814109, 28.171161259150285, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙申大科技有限公司', value: [112.88667787548272, 28.209853799967913, 14], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙玫瑰园一期停车场', value: [112.92316217167273, 28.281981478147372, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙火车站', value: [113.00558715857031, 28.19731290731289, -1], symbolSize: 10, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '长沙润锦汽车销售服务有限公司', value: [112.85215998929705, 28.207700626725376, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙海底世界', value: [113.0452768158602, 28.23612607994362, 4], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙星辉汽车销售服务有限公司', value: [112.85210114490857, 28.210324812738573, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙新奥燃气储备站', value: [112.88606200203769, 28.22883999732146, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙新农大酒店西停车场', value: [113.07800519688782, 28.18865497752483, 10], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市高新师大附属实验中学', value: [112.85118107537747, 28.22046991744453, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市紫凤体育休闲公园', value: [112.97158351135828, 28.23049352712987, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市第四医院停车场', value: [112.9448948030706, 28.197240654849228, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市第三医院', value: [112.97205478473022, 28.179410188379013, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市第一医院', value: [112.97612024447776, 28.20836596266506, 1], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市建安五处', value: [112.99315774325795, 28.237123220129412, -4], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '长沙市工人文化宫', value: [112.98834129529442, 28.167871235635147, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市城区公共停车场', value: [113.02580608972076, 28.205419909687446, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙市国土资源局停车场', value: [113.00641043566961, 28.209118942941345, 8], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙宝利德汽车有限公司', value: [112.96510892555996, 28.20317525207825, 7], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙大道高架桥停车场', value: [113.01854252638519, 28.173103221688255, -1], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '长沙大学停车场', value: [113.02284250932999, 28.24807272631272, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙四方坪桥下停车场', value: [113.01274106420317, 28.23916072100296, 24], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙南火车站西广场地下停车', value: [113.05795429361524, 28.150370311933635, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙华时捷环保科技发展有限公司', value: [112.87286068080537, 28.233611270783587, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房汀兰雅苑', value: [112.96412988943281, 28.195843585715334, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙佳海产业园', value: [112.996803, 28.353181, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙中联重工科技发展股份有限公司', value: [112.9478261122938, 28.224837649895264, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙世纪金源购物中心地面停车场', value: [112.97362659491549, 28.25515787645171, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长沙万达文华酒店', value: [112.96536327027059, 28.203951215213554, 28], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房汀兰雅苑', value: [112.96412988943281, 28.195843585715334, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房时代城', value: [112.90297819331016, 28.223573460400416, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房东郡', value: [113.0202916422248, 28.191071778339825, 13], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房·白沙湾', value: [113.03868908577925, 28.19619580472909, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房·时代城', value: [112.90276050069677, 28.224997942436996, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长房·东郡华城广场', value: [113.02447612054468, 28.19023687471548, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长安停车场', value: [112.85215998929705, 28.207700626725376, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '长华小区', value: [112.91033037317466, 28.225131249945665, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦里停车场', value: [112.98450590919816, 28.257513448411824, 44], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦苑小区-四号停车场', value: [113.05577035947981, 28.230301011139534, -23], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '锦苑小区-三号停车场', value: [113.05566318328778, 28.23059384797019, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦绣红楼', value: [112.99587909649982, 28.20114378917712, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦绣家园', value: [112.90027802192046, 28.20667847804545, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦绣中环', value: [112.97007666532741, 28.198029231767, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦绣华天', value: [112.98117044047606, 28.222150840728172, 14], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦尚家纺厂', value: [112.9456398444377, 28.242095006659422, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦和园', value: [112.87509311765044, 28.209942595355653, 15], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '锦和嘉园', value: [113.002647, 28.235702, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银轩铭誉', value: [112.98225386541851, 28.175631806898686, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银盆大厦', value: [112.94880298197276, 28.225379743354246, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银港水晶城c区', value: [113.02621015891279, 28.205002036961268, 40], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银港水晶城', value: [113.02550914545532, 28.206290268731255, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银海大厦', value: [112.97957547778064, 28.20334130101935, 14], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银河科技园', value: [112.89479574996189, 28.223122911460248, 4], symbolSize: 3, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银河大酒店', value: [113.00082303464582, 28.197244396737577, -1], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '银宏大厦', value: [112.9762511824853, 28.194775436700848, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '银元公寓', value: [113.07944498590123, 28.241033007588953, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '铭城国际', value: [113.0153569050876, 28.186887512733332, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '铁路文化宫', value: [112.99833341380959, 28.19721515433875, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '钱隆首府', value: [112.97444135948028, 28.172440428698376, 10], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '钰龙天下佳园', value: [112.93078221265013, 28.247115766714742, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鑫远白天鹅', value: [112.98750044636702, 28.11606433534482, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鑫远熙山', value: [112.96538575640714, 28.124086919490935, 2], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鑫远尚玺', value: [112.9713063360014, 28.113632357691767, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '鑫远华城', value: [112.98486927890349, 28.12416073917859, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金麓国际地下停车场', value: [112.9310297642296, 28.228119871074643, -4], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '金麓国际商务中心', value: [112.93077518666364, 28.228374669869538, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金鹰小区', value: [113.03756072725847, 28.241202049229575, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金达家园', value: [113.002363, 28.233476, 8], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金赋大酒店', value: [112.98004432998498, 28.1741535627574, 7], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金谷大厦', value: [112.92820979882404, 28.219280754984673, -1], symbolSize: 2, itemStyle: { normal: { color: '#58B3CC' } } },
    { name: '金荣央谷金座', value: [112.89065697349177, 28.22006529481333, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金荣会馆', value: [112.8907433866023, 28.220019893998273, 24], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金荣企业公园', value: [112.85062300531925, 28.30015853914429, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金茂府停车场', value: [112.89399032128814, 28.198403552928948, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金苹果停车场', value: [113.00632340198425, 28.18869567220106, 3], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金苑商住楼', value: [112.97761836695364, 28.159813282910395, 4], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金色比华利大厦', value: [113.04818041540591, 28.234003127821392, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金色比华利地下停车场', value: [113.04810002003558, 28.234105771428386, 1], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '金色屋顶', value: [112.87286068080537, 28.233611270783587, 28], symbolSize: 2, itemStyle: { normal: { color: '#F58158' } } },
    { name: '五矿科技产业园', value: [112.900761, 28.228818, 28], symbolSize: 20, itemStyle: { normal: { color: '#F58158' } } }
  ],
  moveLines: [{ fromName: '中国农业科学院麻类研究所', toName: '麓谷新世界', coords: [[112.89708431206891, 28.215208345690932], [112.85674484919257, 28.205020779861957]] },
    { fromName: '龙骧集团', toName: '麓谷小镇停车场', coords: [[113.00256316794182, 28.19955509585358], [112.891472, 28.238239]] },
    { fromName: '龙湾壹号', toName: '麓谷小学', coords: [[112.98787389638836, 28.253629646009944], [112.88684696797736, 28.21690336971796]] },
    { fromName: '龙之梦臻品酒店', toName: '麓谷坐标停车场', coords: [[112.99176610566388, 28.196860105895862], [112.88898817900363, 28.216899695499354]] },
    { fromName: '麓阳和景', toName: '麓谷国际工业园', coords: [[112.90385644570027, 28.205814020811914], [112.86891870868178, 28.222378021382337]] },
    { fromName: '麓谷林语c区地下停车场', toName: '麓谷和馨园b区', coords: [[112.86757521393805, 28.22890113054207], [112.85230074390955, 28.214894676516295]] },
    { fromName: '麓谷林语E1区停车场', toName: '麓谷企业广场', coords: [[112.87490224963537, 28.227156232906104], [112.880354547264, 28.2121464340884]] },
    { fromName: '麓谷林语BⅡ区停车场', toName: '麓谷·沐春园', coords: [[112.8711577325996, 28.225806216734682], [112.8989535105798, 28.220252892897978]] },
    { fromName: '麓谷星辰停车场', toName: '麓景裕园', coords: [[112.85727525404215, 28.21984433111654], [112.89596859160093, 28.210829085715538]] },
    { fromName: '麓谷明珠', toName: '麓景新贵停车场', coords: [[112.88781954253187, 28.207690063536347], [113.01443243146612, 28.212867076100007]] },
    { fromName: '麓山润城小区', toName: '高鑫麓城二期', coords: [[112.87824948015889, 28.203338409524], [112.9224723352201, 28.221317112003604]] },
    { fromName: '麓山枫情停车场', toName: '高桥电脑大世界', coords: [[112.905646865273, 28.205975581567007], [113.01934712886595, 28.183406632716075]] },
    { fromName: '鹿城饭店停车场', toName: '高桥现代商贸城增补项目', coords: [[112.66748540538694, 28.015197332998042], [113.01815277527999, 28.17696790978483]] },
    { fromName: '鹏程世家', toName: '高桥现代商贸城', coords: [[112.98729410820194, 28.205952748917944], [113.0181466731061, 28.176962498248866]] },
    { fromName: '鹏基·诺亚山林西区', toName: '高桥国际', coords: [[113.09708983297733, 28.246000338898273], [113.0199972539599, 28.1721492285105]] },
    { fromName: '鸿铭中心', toName: '高晟华成', coords: [[112.99402084912596, 28.18669811966935], [112.88004583462049, 28.20228406844622]] },
    { fromName: '鸿江苑', toName: '高新科技园', coords: [[112.97086924944637, 28.267074476923156], [120.165002837929, 30.2879470065532]] },
    { fromName: '鸿景雅苑', toName: '高新区地税局', coords: [[112.9847020031389, 28.18648370047947], [112.88938656328259, 28.219695260200634]] },
    { fromName: '鸿信大厦', toName: '高信向日葵中区', coords: [[112.96946034147378, 28.186497013222162], [112.92024742161556, 28.21440777308867]] },
    { fromName: '鸡鸭恋食堂', toName: '骏达大厦', coords: [[112.97938619284453, 28.184578138239452], [112.83942548978376, 28.202282664946534]] },
    { fromName: '香海·西岸', toName: '颐美会现代城', coords: [[112.95071879950294, 28.232282599547904], [112.97991764149602, 28.192556167199026]] },
    { fromName: '香泽南湖大厦', toName: '领御大厦', coords: [[113.00477919084878, 28.20676577180484], [112.97161859307151, 28.207517024239504]] },
    { fromName: '香江锦园', toName: '领东汇停车场', coords: [[112.981806, 28.222587], [113.12520860234208, 28.202205896727744]] },
    { fromName: '香江·世纪名城', toName: '顺风楼', coords: [[117.32894935118046, 31.877517886717207], [113.00503386796328, 28.210293096394928]] },
    { fromName: '香樟鑫都', toName: '顺天黄金海岸', coords: [[113.02332479000673, 28.14034621999733], [112.97954799101156, 28.25338889957112]] },
    { fromName: '香樟景苑', toName: '顺天·黄金海岸大酒店', coords: [[113.01563979400574, 28.140389617266433], [112.97995365016743, 28.25204388683466]] },
    { fromName: '香樟·兰亭', toName: '青竹湖霞凝小区', coords: [[113.0223937491106, 28.141662334185153], [112.94885687457477, 28.348068]] },
    { fromName: '香槟花苑', toName: '青竹湖国际会展中心', coords: [[113.07927411218313, 28.235668678977543], [112.95608360674596, 28.34408067824035]] },
    { fromName: '香槟小镇', toName: '青税小区', coords: [[113.07943510105208, 28.23504201968422], [114.3871895583972, 30.62105259460301]] },
    { fromName: '颐而康停车处', toName: '青枫大厦', coords: [[112.97795127590886, 28.186466184667577], [112.94622406122788, 28.19729366811922]] },
    { fromName: '雨花区公安局', toName: '长远大厦', coords: [[113.01966514865555, 28.148659902140526], [112.97976288924615, 28.204373586777766]] },
    { fromName: '集星码头', toName: '长缆电工科技股份有限公司', coords: [[112.93244572720437, 28.32442099343107], [112.8732799142657, 28.223840338309376]] },
    { fromName: '雅致名园', toName: '长沙鼎道汽车销售服务有限公司', coords: [[113.04498257736587, 28.143973608495752], [112.85255197723336, 28.207178165217577]] },
    { fromName: '雅尊酒店', toName: '长沙黄花国际机场-t1航站楼', coords: [[112.99255571516659, 28.201232649481728], [113.21114025174909, 28.195978306653462]] },
    { fromName: '雅和苑停车场', toName: '长沙高新技术工程学校', coords: [[112.93776541341845, 28.134094501925482], [112.85589084011812, 112.85589084011812]] },
    { fromName: '雄新华府', toName: '沙驰宝汽车销售服务有限公司', coords: [[113.01141585697091, 28.25331749867126], [112.85504351423941, 28.20708582076012]] },
    { fromName: '阿波罗商业广场', toName: '长沙鑫广合汽车销售服务有限公司', coords: [[113.00346238146925, 28.200428296200446], [112.85393888402565, 28.212786776830338]] },
    { fromName: '阳光锦城', toName: '长沙金属包装厂', coords: [[112.99196582066985, 28.15737765416692], [112.99443371029386, 28.236021990017587]] },
    { fromName: '阳光晶诚', toName: '长沙软件园', coords: [[112.90102576640005, 112.90102576640005], [112.88769681404735, 28.21832375728395]] },
    { fromName: '阳光新城', toName: '长沙路德行汽车有限公司', coords: [[112.93820933196204, 28.233812811969077], [112.85502441511231, 28.209962143338633]] },
    { fromName: '长沙赛格数码广场', toName: '长沙润锦汽车销售服务有限公司', coords: [[113.00436475152112, 28.197187094912376], [112.85215998929705, 28.207700626725376]] },
    { fromName: '长沙裕麓汽车销售服务有限公司', toName: '长沙海底世界', coords: [[112.85351974738344, 28.20663617477256], [113.0452768158602, 28.23612607994362]] },
    { fromName: '长沙艺术职业学院', toName: '长沙星辉汽车销售服务有限公司', coords: [[112.93203532205392, 28.171016227863806], [112.85210114490857, 28.210324812738573]] },
    { fromName: '长沙美爵酒店', toName: '长沙新奥燃气储备站', coords: [[112.9703242096342, 28.19933825192662], [112.88606200203769, 28.22883999732146]] },
    { fromName: '长沙美东雷克萨斯汽车销售服务有限公司', toName: '长沙新农大酒店西停车场', coords: [[112.85483781571625, 28.208354286316183], [113.07800519688782, 28.18865497752483]] },
    { fromName: '长沙第5大道1号停车场', toName: '长沙市高新师大附属实验中学', coords: [[113.00503391938938, 28.1810899637132], [112.85118107537747, 28.22046991744453]] },
    { fromName: '长沙矿山研究院二村停车场', toName: '长沙市紫凤体育休闲公园', coords: [[112.93747616814109, 28.171161259150285], [112.97158351135828, 28.23049352712987]] },
    { fromName: '长沙申大科技有限公司', toName: '长沙市第四医院停车场', coords: [[112.88667787548272, 28.209853799967913], [112.9448948030706, 28.197240654849228]] },
    { fromName: '长沙玫瑰园一期停车场', toName: '长沙市第三医院', coords: [[112.92316217167273, 28.281981478147372], [112.97205478473022, 28.179410188379013]] },
    { fromName: '长沙火车站', toName: '长沙市第一医院', coords: [[113.00558715857031, 28.19731290731289], [112.97612024447776, 28.20836596266506]] },
    { fromName: '长沙市建安五处', toName: '长房汀兰雅苑', coords: [[112.99315774325795, 28.237123220129412], [112.96412988943281, 28.195843585715334]] },
    { fromName: '长沙市工人文化宫', toName: '长沙佳海产业园', coords: [[112.98834129529442, 28.167871235635147], [112.996803, 28.353181]] },
    { fromName: '长沙市城区公共停车场', toName: '长沙中联重工科技发展股份有限公司', coords: [[113.02580608972076, 28.205419909687446], [112.9478261122938, 28.224837649895264]] },
    { fromName: '长沙市国土资源局停车场', toName: '长沙世纪金源购物中心', coords: [[113.00641043566961, 28.209118942941345], [112.97362659491549, 28.25515787645171]] },
    { fromName: '长沙宝利德汽车有限公司', toName: '长沙万达文华酒店', coords: [[112.96510892555996, 28.20317525207825], [112.96536327027059, 28.203951215213554]] },
    { fromName: '长沙大道高架桥停车场', toName: '中国农业科学院麻类研究所', coords: [[113.01854252638519, 28.173103221688255], [112.89708431206891, 28.215208345690932]] },
    { fromName: '长沙大学停车场', toName: '龙骧集团', coords: [[113.02284250932999, 28.24807272631272], [113.00256316794182, 28.19955509585358]] },
    { fromName: '长沙四方坪桥下停车场', toName: '龙湾壹号', coords: [[113.01274106420317, 28.23916072100296], [112.98787389638836, 28.253629646009944]] },
    { fromName: '长沙南火车站西广场地下停车', toName: '龙之梦臻品酒店', coords: [[113.05795429361524, 28.150370311933635], [112.99176610566388, 28.196860105895862]] },
    { fromName: '长沙华时捷环保科技发展有限公司', toName: '麓阳和景', coords: [[112.87286068080537, 28.233611270783587], [112.90385644570027, 28.205814020811914]] },
    { fromName: '长房汀兰雅苑', toName: '锦苑小区-三号停车场', coords: [[112.96412988943281, 28.195843585715334], [113.05566318328778, 28.23059384797019]] },
    { fromName: '长房时代城', toName: '锦绣红楼', coords: [[112.90297819331016, 28.223573460400416], [112.99587909649982, 28.20114378917712]] },
    { fromName: '长房东郡', toName: '锦绣家园', coords: [[113.0202916422248, 28.191071778339825], [112.90027802192046, 28.20667847804545]] },
    { fromName: '长房·白沙湾', toName: '锦绣中环', coords: [[113.03868908577925, 28.19619580472909], [112.97007666532741, 28.198029231767]] },
    { fromName: '长房·时代城', toName: '锦绣华天', coords: [[112.90276050069677, 28.224997942436996], [112.98117044047606, 28.222150840728172]] },
    { fromName: '长房·东郡华城广场', toName: '锦尚家纺厂', coords: [[113.02447612054468, 28.19023687471548], [112.9456398444377, 28.242095006659422]] },
    { fromName: '长安停车场', toName: '锦和园', coords: [[112.85215998929705, 28.207700626725376], [112.87509311765044, 28.209942595355653]] },
    { fromName: '长华小区', toName: '锦和嘉园', coords: [[112.91033037317466, 28.225131249945665], [113.002647, 28.235702]] },
    { fromName: '锦里停车场', toName: '银轩铭誉', coords: [[112.98450590919816, 28.257513448411824], [112.98225386541851, 28.175631806898686]] },
    { fromName: '锦苑小区-四号停车场', toName: '银盆大厦', coords: [[113.05577035947981, 28.230301011139534], [112.94880298197276, 28.225379743354246]] },
    { fromName: '银港水晶城c区', toName: '钰龙天下佳园', coords: [[113.02621015891279, 28.205002036961268], [112.93078221265013, 28.247115766714742]] },
    { fromName: '银港水晶城', toName: '鑫远白天鹅', coords: [[113.02550914545532, 28.206290268731255], [112.98750044636702, 28.11606433534482]] },
    { fromName: '银海大厦', toName: '鑫远熙山', coords: [[112.97957547778064, 28.20334130101935], [112.96538575640714, 28.124086919490935]] },
    { fromName: '银河科技园', toName: '鑫远尚玺', coords: [[112.89479574996189, 28.223122911460248], [112.9713063360014, 28.113632357691767]] },
    { fromName: '银河大酒店', toName: '鑫远华城', coords: [[113.00082303464582, 28.197244396737577], [112.98486927890349, 28.12416073917859]] },
    { fromName: '银宏大厦', toName: '金麓国际地下停车场', coords: [[112.9762511824853, 28.194775436700848], [112.9310297642296, 28.228119871074643]] },
    { fromName: '银元公寓', toName: '金麓国际商务中心', coords: [[113.07944498590123, 28.241033007588953], [112.93077518666364, 28.228374669869538]] },
    { fromName: '铭城国际', toName: '金鹰小区', coords: [[113.0153569050876, 28.186887512733332], [113.03756072725847, 28.241202049229575]] },
    { fromName: '铁路文化宫', toName: '金达家园', coords: [[112.99833341380959, 28.19721515433875], [113.002363, 28.233476]] },
    { fromName: '钱隆首府', toName: '金赋大酒店', coords: [[112.97444135948028, 28.172440428698376], [112.98004432998498, 28.1741535627574]] },
    { fromName: '金谷大厦', toName: '麓谷企业广场', coords: [[112.92820979882404, 28.219280754984673], [112.880354547264, 28.2121464340884]] },
    { fromName: '金荣央谷金座', toName: '高桥国际', coords: [[112.89065697349177, 28.22006529481333], [113.0199972539599, 28.1721492285105]] },
    { fromName: '金荣会馆', toName: '长沙黄花国际机场-t1航站楼', coords: [[112.8907433866023, 28.220019893998273], [113.21114025174909, 28.195978306653462]] },
    { fromName: '金荣企业公园', toName: '高桥国际', coords: [[112.85062300531925, 28.30015853914429], [113.0199972539599, 28.1721492285105]] },
    { fromName: '金茂府停车场', toName: '麓谷企业广场', coords: [[112.89399032128814, 28.198403552928948], [112.880354547264, 28.2121464340884]] },
    { fromName: '金苹果停车场', toName: '高桥国际', coords: [[113.00632340198425, 28.18869567220106], [113.0199972539599, 28.1721492285105]] },
    { fromName: '金苑商住楼', toName: '长沙黄花国际机场-t1航站楼', coords: [[112.97761836695364, 28.159813282910395], [113.21114025174909, 28.195978306653462]] },
    { fromName: '金色比华利大厦', toName: '长沙黄花国际机场-t1航站楼', coords: [[113.04818041540591, 28.234003127821392], [113.21114025174909, 28.195978306653462]] },
    { fromName: '金色比华利地下停车场', toName: '麓谷企业广场', coords: [[113.04810002003558, 28.234105771428386], [112.880354547264, 28.2121464340884]] },
    { fromName: '金色屋顶', toName: '高桥国际', coords: [[112.87286068080537, 28.233611270783587], [113.0199972539599, 28.1721492285105]] },
    { fromName: '五矿科技产业园', toName: '麓谷国际工业园', coords: [[112.900761, 28.228818], [112.86891870868178, 28.222378021382337]] },
    { fromName: '五矿科技产业园', toName: '麓谷企业广场', coords: [[112.900761, 28.228818], [112.880354547264, 28.2121464340884]] },
    { fromName: '五矿科技产业园', toName: '麓山枫情停车场', coords: [[112.900761, 28.228818], [112.905646865273, 28.205975581567007]] },
    { fromName: '五矿科技产业园', toName: '鸿江苑', coords: [[112.900761, 28.228818], [112.97086924944637, 28.267074476923156]] },
    { fromName: '五矿科技产业园', toName: '鸿景雅苑', coords: [[112.900761, 28.228818], [112.9847020031389, 28.18648370047947]] },
    { fromName: '五矿科技产业园', toName: '香海·西岸', coords: [[112.900761, 28.228818], [112.95071879950294, 28.232282599547904]] },
    { fromName: '五矿科技产业园', toName: '香江锦园', coords: [[112.900761, 28.228818], [112.981806, 28.222587]] },
    { fromName: '五矿科技产业园', toName: '香樟鑫都', coords: [[112.900761, 28.228818], [113.02332479000673, 28.14034621999733]] },
    { fromName: '五矿科技产业园', toName: '麓山润城小区', coords: [[112.900761, 28.228818], [112.87824948015889, 28.203338409524]] },
    { fromName: '高桥国际', toName: '麓谷国际工业园', coords: [[113.0199972539599, 28.1721492285105], [112.86891870868178, 28.222378021382337]] },
    { fromName: '高桥国际', toName: '麓谷企业广场', coords: [[113.0199972539599, 28.1721492285105], [112.880354547264, 28.2121464340884]] },
    { fromName: '高桥国际', toName: '麓谷新世界', coords: [[113.0199972539599, 28.1721492285105], [112.85674484919257, 28.205020779861957]] },
    { fromName: '高桥国际', toName: '麓阳和景', coords: [[113.0199972539599, 28.1721492285105], [112.90385644570027, 28.205814020811914]] },
    { fromName: '高桥国际', toName: '麓谷小镇停车场', coords: [[113.0199972539599, 28.1721492285105], [112.891472, 28.238239]] },
    { fromName: '高桥国际', toName: '麓谷坐标停车场', coords: [[113.0199972539599, 28.1721492285105], [112.88898817900363, 28.216899695499354]] },
    { fromName: '高桥国际', toName: '麓谷和馨园b区', coords: [[113.0199972539599, 28.1721492285105], [112.85230074390955, 28.214894676516295]] },
    { fromName: '高桥国际', toName: '香樟·兰亭', coords: [[113.0199972539599, 28.1721492285105], [113.0223937491106, 28.141662334185153]] },
    { fromName: '高桥国际', toName: '香槟花苑', coords: [[113.0199972539599, 28.1721492285105], [113.07927411218313, 28.235668678977543]] },
    { fromName: '高桥国际', toName: '颐而康停车处', coords: [[113.0199972539599, 28.1721492285105], [112.97795127590886, 28.186466184667577]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '麓谷国际工业园', coords: [[113.21114025174909, 28.195978306653462], [112.86891870868178, 28.222378021382337]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '麓谷企业广场', coords: [[113.21114025174909, 28.195978306653462], [112.880354547264, 28.2121464340884]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '麓谷新世界', coords: [[113.21114025174909, 28.195978306653462], [112.85674484919257, 28.205020779861957]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '颐美会现代城', coords: [[113.21114025174909, 28.195978306653462], [112.97991764149602, 28.192556167199026]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '领东汇停车场', coords: [[113.21114025174909, 28.195978306653462], [113.12520860234208, 28.202205896727744]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '顺天黄金海岸', coords: [[113.21114025174909, 28.195978306653462], [112.97954799101156, 28.25338889957112]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '青竹湖霞凝小区', coords: [[113.21114025174909, 28.195978306653462], [112.94885687457477, 28.348068]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '集星码头', coords: [[113.21114025174909, 28.195978306653462], [112.93244572720437, 28.32442099343107]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '雅尊酒店', coords: [[113.21114025174909, 28.195978306653462], [112.99255571516659, 28.201232649481728]] },
    { fromName: '长沙黄花国际机场-t1航站楼', toName: '阿波罗商业广场', coords: [[113.21114025174909, 28.195978306653462], [113.00346238146925, 28.200428296200446]] },
    { fromName: '阿波罗商业广场', toName: '麓谷国际工业园', coords: [[113.00346238146925, 28.200428296200446], [112.86891870868178, 28.222378021382337]] },
    { fromName: '阿波罗商业广场', toName: '麓谷企业广场', coords: [[113.00346238146925, 28.200428296200446], [112.880354547264, 28.2121464340884]] },
    { fromName: '阿波罗商业广场', toName: '麓谷新世界', coords: [[113.00346238146925, 28.200428296200446], [112.85674484919257, 28.205020779861957]] },
    { fromName: '阿波罗商业广场', toName: '阳光锦城', coords: [[113.00346238146925, 28.200428296200446], [112.99196582066985, 28.15737765416692]] },
    { fromName: '阿波罗商业广场', toName: '长远大厦', coords: [[113.00346238146925, 28.200428296200446], [112.97976288924615, 28.204373586777766]] },
    { fromName: '麓谷国际工业园', toName: '长沙火车站', coords: [[112.86891870868178, 28.222378021382337], [113.00558715857031, 28.19731290731289]] },
    { fromName: '麓谷国际工业园', toName: '麓谷企业广场', coords: [[112.86891870868178, 28.222378021382337], [112.880354547264, 28.2121464340884]] },
    { fromName: '麓谷国际工业园', toName: '麓谷新世界', coords: [[112.86891870868178, 28.222378021382337], [112.85674484919257, 28.205020779861957]] },
    { fromName: '麓谷国际工业园', toName: '长沙软件园', coords: [[112.86891870868178, 28.222378021382337], [112.88769681404735, 28.21832375728395]] },
    { fromName: '麓谷国际工业园', toName: '长沙赛格数码广场', coords: [[112.86891870868178, 28.222378021382337], [113.00436475152112, 28.197187094912376]] }
  ]
}
export default {
  backgroundColor: '#F2F2F2', // 地图背景颜色
  title: {
    text: '车流动态趋势监测实况', // 地图标题设置
    subtext: '', // 地图小标题设置
    sublink: 'http://cloud.lebopark.com', // 小标题链接
    target: 'blank', // 'self' 当前窗口打开，'blank' 新窗口打开
    padding: 5, // 标题内边距 5  [5, 10]  [5,10,5,10]
    left: 'center', // 组件离容器左侧的距离,'left', 'center', 'right','20%'
    top: '5%', // 组件离容器上侧的距离,'top', 'middle', 'bottom','20%'
    right: 'auto', // 组件离容器右侧的距离,'20%'
    bottom: 'auto', // 组件离容器下侧的距离,'20%'
    textStyle: {
      color: '#333333', // 文字颜色
      fontStyle: 'normal', // italic斜体  oblique倾斜
      fontWeight: '800', // 文字粗细bold   bolder   lighter  100 | 200 | 300 | 400...
      fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei', // 字体系列
      fontSize: 30 // 字体大小
    }
  },
  legend: { // 右下角图例
    show: false, // 是否显示
    orient: 'vertical', // 图例排列方向
    top: 'bottom', // 位置
    left: 'right', // 位置
    data: ['地点', '线路'], // 数据
    textStyle: { // 文字设置
      color: '#fff'
    }
  },
  geo: {
    show: true, // 是否显示
    map: '长沙', // 地图类型。world世界地图，china中国地图
    zoom: 6,
    center: [113.00558715857031, 28.19731290731289], // 当前视角的中心点，用经纬度表示
    label: {
      normal: { // 普通状态下的标签文本样式。(省份名称)
        show: false, // 是否在普通状态下显示标签。
        textStyle: { // 文字设置
          color: '#333'
        }
      },
      emphasis: { // 是否在高亮状态下显示标签。(省份名称)
        show: true,
        textStyle: { // 文字设置
          color: '#666666', // 文字颜色
          fontStyle: 'normal', // italic斜体  oblique倾斜
          fontWeight: '500', // 文字粗细bold   bolder   lighter  100 | 200 | 300 | 400...
          fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei', // 字体系列
          fontSize: 16 // 字体大小
        }
      }
    },
    roam: true, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
    itemStyle: { // 地图板块区域的多边形 图形样式
      normal: { // 默认板块信息
        areaColor: '#F2F2F2',
        borderColor: '#BFBFBF'
      },
      emphasis: { // 高亮版板块信息
        areaColor: '#EDF2FA',
        borderColor: '#01A0F2'
      }
    }
  },
  series: [{
    name: '地点',
    type: 'effectScatter', // 特效散点图
    coordinateSystem: 'geo', // 'cartesian2d'使用二维的直角坐标系。'geo'使用地理坐标系
    zlevel: 2, // 所有图形的 zlevel 值。
    rippleEffect: { // 涟漪特效相关配置。
      brushType: 'stroke', // 波纹的绘制方式，可选 'stroke' 和 'fill'。
      period: 4, // 动画的时间。
      scale: 2.5 // 动画中波纹的最大缩放比例。
    },
    label: {
      normal: {
        show: false, // 是否显示标签。
        position: 'inside', // 标签的位置。// 绝对的像素值[10, 10],// 相对的百分比['50%', '50%'].'top','left','right','bottom','inside','insideLeft','insideRight','insideTop','insideBottom','insideTopLeft','insideBottomLeft','insideTopRight','insideBottomRight'
        offset: [30, 40], // 是否对文字进行偏移。默认不偏移。例如：[30, 40] 表示文字在横向上偏移 30，纵向上偏移 40。
        formatter: '{b}: {c}' // 标签内容格式器。模板变量有 {a}、{b}、{c}，分别表示系列名，数据名，数据值。
      },
      emphasis: {
        show: true,
        position: 'right',
        formatter: '{b}'
      }
    },
    symbolSize: 2, // 标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
    showEffectOn: 'render', // 配置何时显示特效。可选：'render' 绘制完成后显示特效。'emphasis' 高亮（hover）的时候显示特效。
    itemStyle: { // 图形样式，normal 是图形在默认状态下的样式；emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时。
      normal: {
        color: '#46bee9'
      }
    },
    data: allData.citys
  }, {
    name: '线路',
    type: 'lines',
    coordinateSystem: 'geo', // 'cartesian2d'使用二维的直角坐标系。'geo'使用地理坐标系
    zlevel: 2,
    large: true, // 是否开启大规模散点图的优化，在数据图形特别多的时候（>=5k）可以开启。开启后配合 largeThreshold 在数据量大于指定阈值的时候对绘制进行优化。缺点：优化后不能自定义设置单个数据项的样式。
    effect: {
      show: true,
      constantSpeed: 30, // 点移动的速度
      symbol: 'pin', // 图形 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
      symbolSize: 3, // 标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
      trailLength: 0 // 线的宽度
    },
    lineStyle: { // 线的颜色、宽度，样式设置
      normal: {
        color: echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0, color: '#58B3CC'
        }, {
          offset: 1, color: '#F58158'
        }], false),
        width: 1, // 线的宽度
        opacity: 0.2, // 线的透明度
        curveness: 0.1 // 线的完全程度
      }
    },
    data: allData.moveLines
  }]
}
